<template>
  <div>
    <v-container>
      <Viewallpds1 />      
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    Viewallpds1: () => import("@/components/viewallpds1"),
  },
};
</script>